import background from '../../assets/background.jpg';
import logo from '../../assets/logo.svg';
import './App.css';

function App() {
  return (
    <div>
      <div className="background">
        <img src={background} alt=""></img>
      </div>
      <div className="logo">
        <img src={logo} alt=""></img>
      </div>
    </div>
  );
}

export default App;
